import React from 'react';
import styles from './Customer.module.scss';

const Customer = () => {
  let swCustomer = [
    {
      title: '72%',
      para: 'Claim SpringVerify is more user-friendly compared to their previous vendor.'
    },
    {
      title: '91%',
      para: 'Believe our API is more secure and trustworthy compared to competitors.'
    },
    {
      title: '81%',
      para: 'of users say they experience faster turnaround times.'
    },
    {
      title: '5X',
      para: 'Faster checks. Get your checks done quicker than ever'
    },
    {
      title: '04',
      para: 'Hour-integration time. One of the lowest in the industry.'
    },
    {
      title: '2x',
      para: 'Quicker turnaround time. Get your verifications done in hours, not days.'
    }
  ];

  return (
    <div className='SVINContainer'>
      <div className={styles.CustomerWP}>
        <div className={styles.CustomerHeader}>
          <h2>Why do customers love us?</h2>
        </div>
        <div className={styles.CustomerWrapParent}>
          <div className={styles.CustomerWrap}>
            {swCustomer.map((content, i) => (
              <div className={styles.Customer} key={i}>
                <div className={styles.CustomerLeft}>
                  <h4>{content.title}</h4>
                </div>
                <div className={styles.CustomerRight}>
                  <p>{content.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
