import React from "react";
import styles from "./CandidateTool.module.scss";

const Solution = () => {
  return (
    <div className="SVINContainer">
      <div className={styles.Solutions}>
        <div className={styles.SolutionsLeft}>
          <h6>Candidate portal</h6>
          <h3>A super-smooth experience that empowers candidates</h3>
          <div className={styles.SolutionsRightMob}>
            <img
              data-src={
                "https://assets-sv-in.gumlet.io/candidate-tool/candidate.svg"
              }
              alt={"smooth experience"}
            />
          </div>
          <p>
            Help your candidates submit the required personal information
            quickly and easily. They can track the status of their background
            check, find answers to questions, and connect with a dedicated
            support team if needed.
          </p>
        </div>
        <div className={styles.SolutionsRight}>
          <img
            data-src={
              "https://assets-sv-in.gumlet.io/candidate-tool/candidate.svg"
            }
            alt={"smooth experience"}
          />
        </div>
      </div>
      <div className={styles.Solutions}>
        <div className={styles.SolutionsLeft}>
          <h6>Plug and play</h6>
          <h3>Works well with the tools you already use</h3>
          <div className={styles.SolutionsRightMob}>
            <img
              data-src={
                "https://assets-sv-in.gumlet.io/candidate-tool/tools.svg"
              }
              alt={"works with your tools"}
            />
          </div>
          <p>
            Our platform plugs into the ATS and HRIS systems that you’re already
            using, so you don’t have to dedicate effort, time, and manpower to
            get started.
          </p>
        </div>
        <div className={styles.SolutionsRight}>
          <img
            data-src={"https://assets-sv-in.gumlet.io/candidate-tool/tools.svg"}
            alt={"works with your tools"}
          />
        </div>
      </div>
    </div>
  );
};

export default Solution;
