import React from "react";
import styles from "./Onboarding.module.scss";

const Onboarding = () => {
  let cards = [
    {
      name: "info.svg",
      desc: "HR sends candidate information",
    },
    {
      name: "candidate.svg",
      desc: "Candidate uploads all documents",
    },
    {
      name: "report.svg",
      desc: "Collect report",
    },
  ];
  return (
    <div className={styles.OnboardingWrap}>
      <div className="SVINContainer">
        <div className={styles.OnboardingHeader}>
          <h2>
            Easy onboarding
            <br />
            Three simple steps to get started
          </h2>
          <p>
            Wave goodbye to boring forms and manual verifications. Instead use
            our cutting-edge technology to perform fast, secure, and reliable
            checks.
          </p>
        </div>

        <div className={styles.OCardWrap}>
          {cards.map((card, i) => (
            <div className={styles.OCard} key={i}>
              <h4>{card.desc}</h4>
              <img
                data-src={`https://assets-sv-in.gumlet.io/onboarding/${card.name}`}
                alt={card.desc}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
