import React from "react";
import styles from "./AllInOne.module.scss";

const AllInOne = () => {
  let cards = [
    {
      name: "id-check.svg",
      desc: "ID Check",
    },
    {
      name: "emp-check.svg",
      desc: "Employment check",
    },
    {
      name: "court-check.svg",
      desc: "Court Case Check",
    },
    {
      name: "edu-check.svg",
      desc: "Education Check",
    },
    {
      name: "ref-check.svg",
      desc: "Reference Check",
    },
    {
      name: "pass-check.svg",
      desc: "Passport check",
    },
    {
      name: "social-media.svg",
      desc: "Social Media Verification",
    },
    {
      name: "db-search.svg",
      desc: "Global database search",
    },
    {
      name: "address-verification.svg",
      desc: "Address Verification",
    },
    {
      name: "credit-history.svg",
      desc: "Credit History Verification",
    },
  ];
  return (
    <div className={styles.allInOneWrap}>
      <div className="SVINContainer">
        <div className={styles.header}>
          <h3>All the checks you need in one place!</h3>
          <p>
            Make timely and informed decisions while hiring with SpringVerify's
            suite of services
          </p>
        </div>

        <div className={styles.allInOne}>
          {cards.map((card, i) => (
            <div key={i}>
              <img
                data-src={`https://assets-sv-in.gumlet.io/all-in-one/${card.name}`}
                alt={card.desc}
              />
              <p>{card.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllInOne;
