import React from "react";
import styles from "./Companies.module.scss";

let companies = [
  "apollo",
  "instamojo",
  "simpl",
  "razorpay",
  "zolo",
  "ge",
  "godrej",
  "open",
  "prestige",
  "flipkart",
  "hero",
  "cred",
];

const Header = () => (
  <>
    <div className={styles.Companies}>
      <div className="SVINContainer">
        <div className={styles.CompaniesHeader}>
          <h2>10,000+</h2>
          <p>
            companies trust SpringVerify for better <br />
            background checks
          </p>
        </div>
        <div className={styles.CompaniesDesktop}>
          {companies.map((c, i) => (
            <img
              data-src={`https://assets-sv-in.gumlet.io/companies/${c}.png`}
              alt={c}
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  </>
);

export default Header;
