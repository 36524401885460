import React from "react";
import styles from "./VerifyCandidates.module.scss";
import CTAs from "../CTAs";

export default () => {
  return (
    <div className={styles.verifyCandidateWrap}>
      <div className="SVINContainer">
        <div className={styles.verifyCandidate}>
          <div className={styles.verifyCandidateLeft}>
            <img
              data-src={
                "https://assets-sv-in.gumlet.io/verify-candidates/illustration.svg"
              } alt={'we can make you better'}
            />
          </div>
          <div className={styles.verifyCandidateRight}>
            <h3>Ready to give us a try?</h3>
            <p>
              We can help you make faster, easier, and more accurate
              verifications, effortlessly.
            </p>
            <CTAs />
          </div>
        </div>
      </div>
    </div>
  );
};
