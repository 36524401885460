import React from "react";
import styles from "./CTAs.module.scss";

export default (props) => {
  return (
    <div className={styles.mainCTAs}>
      <a href="#ctaBtn" className={styles.primary} rel="noreferrer">
        Request a Demo
      </a>
    </div>
  );
};
