import React from "react";
import { Helmet } from "react-helmet";
import SEO from "../../components/seo";
import "bootstrap/dist/css/bootstrap.min.css";
import Companies from "../../components/Companies";
import Solutions from "../../components/Solutions";
import Customer from "../../components/Customer";
import AllInOne from "../../components/AllInOne";
import Onboarding from "../../components/Onboarding";
import CandidateTool from "../../components/CandidateTool";
import VerifyCandidates from "../../components/VerifyCandidates";
import Header from "../../components/CoreComponents/Header";
import Banner from "../../components/CoreComponents/Banner";
import Testimonials from "../../components/Testimonials";

const IndexPage = () => {
  return (
    <div className="PageWrap">
      <Helmet>
        <title>
          Fast, Reliable Background Verification in India | SpringVerify
        </title>
        <meta
          name="description"
          content="B2B background verification service in India - court record, education, employment and address checks (with conventional and digital address verification)."
        />
        <meta property="og:site_name" content="SpringVerify" />
        <meta
          property="og:title"
          content="Fast, Reliable Background Verification in India | SpringVerify"
        />
        <meta
          property="og:description"
          content="B2B background verification service in India - court record, education, employment and address checks (with conventional and digital address verification)."
        />
        <meta property="og:url" content="https://in.springverify.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="image URL" />
        <meta property="og:image:secure_url" content="image URL" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />
        <meta
          property="twitter:title"
          content="Fast, Reliable Background Verification in India | SpringVerify"
        />
        <meta
          property="twitter:description"
          content="B2B background verification service in India - court record, education, employment and address checks (with conventional and digital address verification)."
        />
        <meta property="twitter:card" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content="image URL" />
        <meta property="twitter:site" content="@springroleinc" />
        <link rel="canonical" href="https://in.springverify.com/" />
      </Helmet>
      <SEO title="SpringVerify" />
      <div className="topSection">
        <Header />
        <Banner />
      </div>
      <Companies />
      <Solutions />
      <Customer />
      <AllInOne />
      <Onboarding />
      <CandidateTool />
      <Testimonials />
      <VerifyCandidates />
    </div>
  );
};

export default IndexPage;
