import * as Sentry from "@sentry/browser";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Slider from "react-slick";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { submitForm } from "./api";
import styles from "./core.module.scss";
import cookie from "react-cookies";

const Banner = () => {
  const [name, setName] = useState(""),
    [email, setEmail] = useState(""),
    [phone, setPhone] = useState(""),
    [company, setCompany] = useState(""),
    [error, setError] = useState(""),
    [submitting, setSubmitting] = useState(false);

  const path =
    typeof window !== `undefined` &&
    window.location.pathname.replace(/\/$/, "");

  const expires = new Date();
  expires.setDate(expires.getDate() + 30);

  useEffect(() => {
    const utmSource = new URLSearchParams(document.location.search).get("utm_source") || null;
    const utmMedium= new URLSearchParams(document.location.search).get("utm_medium") || null;
    const utmCampaign = new URLSearchParams(document.location.search).get("utm_campaign") || null;
    const utmKeyword = new URLSearchParams(document.location.search).get("keyword") || null;
    const urlQueryString = window.location.search ? window.location.search.replace("?", "") : null;
    if(utmSource){
      cookie.save("utmSource", utmSource, { path: "/", expires});
    }
    if(utmMedium){
      cookie.save("utmMedium", utmMedium, { path: "/", expires});
    }
    if(utmCampaign){
      cookie.save("utmCampaign", utmCampaign, { path: "/", expires});
    } 
    if(utmKeyword){
      cookie.save("utmKeyword", utmKeyword, { path: "/", expires});
    } 
    if(urlQueryString){
      cookie.save("urlQueryString", urlQueryString, { path: "/", expires});
    }

  },[]);


  let settings = {
    autoplay: true,
    infinite: true,
    speed: 2000,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  const handlePhoneInput = (value) => {
    setPhone(value);
  };

  const handleErrorChange = (key) => {
    if (error[key]) {
      let newErrorObj = { ...error };
      newErrorObj[key] = "";
      setError(newErrorObj);
    }
  };

  const validateInputs = () => {
    const error = {};

    if (!name) {
      error["name"] = "Name is required";
    }
    if (
      !email ||
      !email.match(
        "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)+$"
      )
    ) {
      error["email"] = "Enter a valid email address";
    }
    if (!phone || !/^\d{10}$/.test(phone)) {
      error["phone"] = "Enter a valid 10 digit number";
    }

    if (!company) {
      error["company"] = "Company name is required";
    }

    setError(error);

    if (JSON.stringify(error) !== "{}") {
      return false;
    }
    return true;
  };

  const submitFormData = () => {
    if (validateInputs()) {
      setSubmitting(true);
      const payload = {
        name,
        email,
        phone: phone,
        company,
      };

      if (typeof window !== `undefined` && typeof document !== `undefined`) {
        payload.utm_source =
          new URLSearchParams(document.location.search).get("utm_source") ||
          cookie.load("utmSource");
        payload.utm_medium =
          new URLSearchParams(document.location.search).get("utm_medium") ||
          cookie.load("utmMedium");
        payload.utm_campaign =
          new URLSearchParams(document.location.search).get("utm_campaign") ||
          cookie.load("utmCampaign");
        payload.keyword =
          new URLSearchParams(document.location.search).get("keyword") || cookie.load("utmKeyword");
        payload.url_query_string = window.location.search
          ? window.location.search.replace("?", "")
          : cookie.load("urlQueryString");
      }

      submitForm(payload)
        .then((response) => {
          setSubmitting(false);
          toast.success("Form submitted Successfully");
          if (typeof window !== `undefined`)
            window.location.href = `${process.env.GATSBY_SV_IN_LANDING}thank-you/`;
        })
        .catch((error) => {
          setSubmitting(false);

          if (typeof window !== `undefined`)
            if (
              !window.location.href.includes("localhost") &&
              !window.location.href.includes("dev.in.springverify")
            )
              Sentry.captureException(error);

          error.response.data.message &&
            toast.error(error.response.data.message);
        });
    }
  };

  return (
    <div className={styles.banner} id="ctaBtn">
      <div className={styles.bannerLeft}>
        <h1>
          <span>Background checks </span>
          <Slider {...settings}>
            <div>
              made <span style={{ color: "#ffc05a" }}>reliable</span>
            </div>
            <div>
              made <span style={{ color: "#37e55e" }}>accurate</span>
            </div>
            <div>
              made <span style={{ color: "#66b3ff" }}>super fast</span>
            </div>
          </Slider>
        </h1>
        <p>
          Cutting-edge background verification technology that helps you make
          informed hiring decisions with industry-leading turnaround times.
        </p>
        {path === "" && (
          <div className={styles.mainCTAs}>
            <a
              href="https://calendly.com/yashwanth-jembige-springworks/15min?back=1&month=2021-06"
              className={styles.primary}
              target="_blank"
              rel="noreferrer"
            >
              Request a Demo
            </a>
          </div>
        )}

        {path === "" && (
          <div className={styles.startChecks}>
            <img
              data-src={"https://assets-sv-in.gumlet.io/header/springIcon.svg"}
              alt={"point"}
            />
            <p>
              Or contact us <a href="tel:+919019906005">+91-9019906005</a>
            </p>
          </div>
        )}
        <div className={styles.contact}>
          {path === "/background-verification" ? (
            <>
              <b>
                <img
                  data-src={
                    "https://assets-sv-in.gumlet.io/header/phone-white.svg"
                  }
                  alt="phone"
                />{" "}
                <a href="tel:+919019906005">+91-9019906005</a>{" "}
              </b>
              <b>
                <img
                  data-src={
                    "https://assets-sv-in.gumlet.io/header/email-white.svg"
                  }
                  alt="email"
                />{" "}
                <a href="mailto:sales@springverify.com">
                  sales@springverify.com
                </a>
              </b>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className={styles.bannerRight}>
        <div className={styles.HeaderRight}>
          <div className={styles.HeaderForm}>
            <>
              <div className={styles.HeaderFormInput}>
                <input
                  placeholder="Name *"
                  type="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    handleErrorChange("name");
                  }}
                />
              </div>
              <span className={styles.info}>{error?.name}</span>
            </>
            <>
              <div className={styles.HeaderFormInput}>
                <input
                  placeholder="Phone *"
                  type="phone"
                  value={phone}
                  onChange={(e) => {
                    handlePhoneInput(e.target.value);
                    handleErrorChange("phone");
                  }}
                />
              </div>
              <span className={styles.info}>{error?.phone}</span>
            </>
            <>
              <div className={styles.HeaderFormInput}>
                <input
                  placeholder="Email ID *"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleErrorChange("email");
                  }}
                />
              </div>
              <span className={styles.info}>{error?.email}</span>
            </>
            <>
              <div className={styles.HeaderFormInput}>
                <input
                  placeholder="Company Name *"
                  type="text"
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                    handleErrorChange("company");
                  }}
                />
              </div>
              <span className={styles.info}>{error?.company}</span>
            </>

            <div className={styles.HeaderFormButton}>
              {!submitting ? (
                <button onClick={() => submitFormData()}>
                  Request Call Back
                </button>
              ) : (
                <div className={styles.spinner}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
